import React, { FC } from 'react';

import ImageBackground from '../components/core/image-background';

import MetaModule from '../components/core/meta-module';

const TemplateApp: FC = () => {
  return (
    <div className="x y f aic jcc">
      <ImageBackground
        source="/peaceful-astronaut.jpg"
        className="z0 mt3"
        position="right top"
      />
      <MetaModule title="App" path="/app" />
      <div className="app">
        <span className="bau h3">Get the Supercluster App</span>
        <div>
          <button
            className="small caps btn"
            onClick={() => {
              window
                .open(
                  'https://apps.apple.com/us/app/supercluster/id1500045135',
                  '_blank'
                )
                .focus();
            }}
          >
            <span>iOS</span>
          </button>
          <button
            className="small caps btn"
            onClick={() => {
              window
                .open(
                  'https://play.google.com/store/apps/details?id=com.supercluster.tracker',
                  '_blank'
                )
                .focus();
            }}
          >
            <span>Android</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplateApp;
